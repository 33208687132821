import { useEffect, useCallback } from 'react'

import { useSelector, useDispatch, batch } from 'react-redux'

import { AUTHOR_URL } from 'constants/config'
import appTypes, { appStatus } from 'redux/constants/AppReducerConstants'
import userActionTypes from 'redux/constants/UserReducerConstants'

import { hasUserReadOnlyAccess } from './hasUserReadOnlyAccess'
import localUserData from './localUserData'

/**
 * useAEM
 *
 * This hooks is responsible for:
 * - Creating the initial connection with AEM (only if the window is inside an iframe)
 * - Loading the user context
 * - Returning some helper variables to help understand the application status
 *
 * In case the AEM user context is not available, this function will assume it
 * is being used with JSON mock server and will mock the user context
 */
const useAEM = () => {
  const dispatch = useDispatch()
  const status = useSelector((state) => state.App.status)
  const setStatus = useCallback(
    (payload) => dispatch({ type: appTypes.APP_CHANGE_STATUS, payload }),
    [dispatch]
  )

  const isAuthenticating = status.id === appStatus.AUTHENTICATING.id
  const isNotAuthorized = status.id === appStatus.NOT_AUTHORIZED.id
  const isInitializing = status.id === appStatus.INITIALIZING.id
  const isLoading = status.id === appStatus.LOADING.id
  const isError = status.id === appStatus.ERROR.id
  const isReady = status.id === appStatus.READY.id

  useEffect(() => {
    if (isInitializing) {
      /**
       * if parent window is defined, that means it is inside an iframe inside AEM, therefore we can
       * post a "ready" message for the handshake and start waiting for aem login token.
       * otherwise in else, assume that token is already loaded and set the to be page ready
       */
      if (window.top !== window.self) {
        setStatus(appStatus.AUTHENTICATING)

        // create a timeout if aem doesn't give a response
        const timeoutId = setTimeout(() => setStatus(appStatus.NOT_AUTHORIZED), 5000)

        window.parent.postMessage({ type: 'INITIAL' }, AUTHOR_URL)
        const handleMessage = (e) => {
          if (e.data && e.data.type && e.data.type === 'INITIAL' && e.data.status) {
            // eslint-disable-next-line no-console
            console.log(`Connection with AEM made! (${e.data.mode})`)
            clearTimeout(timeoutId)
            batch(() => {
              // Get project code from pathname
              // Example pathname: /builder/content/dnb/open/somepage
              const projectCode = window.location.pathname.split('/')[3]
              // Change application mode
              const isInReadOnlyMode =
                e.data.mode === 'PREVIEW' ||
                (e.data.mode === 'BUILDER' && hasUserReadOnlyAccess(e.data.data, projectCode))
              dispatch({
                type: appTypes.APP_SET_IS_READONLY_MODE,
                payload: isInReadOnlyMode
              })
              // Load user context
              dispatch({ type: userActionTypes.USER_LOAD, payload: e.data.data })
            })
            // Change application status
            setStatus(appStatus.READY)
            window.removeEventListener('message', handleMessage)
          }
        }

        window.addEventListener('message', handleMessage)
      } else if (!import.meta.env.REACT_APP_AWS_ENV) {
        // Load user context
        dispatch({
          type: userActionTypes.USER_LOAD,
          payload: localUserData
        })
        // Change application status
        setStatus(appStatus.READY)
      } else {
        setStatus(appStatus.NOT_AUTHORIZED)
      }
    }
  }, [isInitializing, setStatus, dispatch])

  return {
    status,
    setStatus,
    appStatus,
    isReady,
    isAuthenticating,
    isLoading,
    isNotAuthorized,
    isInitializing,
    isError
  }
}

export default useAEM
