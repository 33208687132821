import { CurrentUserType } from 'redux/reducers/UserReducer/types'

export const hasUserReadOnlyAccess = (data?: CurrentUserType, projectCode?: string): boolean => {
  if (data?.declaredMemberOf && projectCode) {
    return data.declaredMemberOf.some(
      (group) => group.authorizableId === `${projectCode}-read-only`
    )
  }

  return true
}
